import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions'
import { Button, CardContent,CardHeader, Typography,Modal, Fade,Backdrop,CircularProgress,Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Settings from '@material-ui/icons/Settings';
import Cookies from 'js-cookie';
import AuthContext from '../../Context/AuthContext';
import HistoryContext from '../../Context/HistoryContext';
import dayjs, { Dayjs } from 'dayjs'
import { API } from "aws-amplify";

const historyStyles = makeStyles((theme)=>({
  historyCard: {
    flex:'60%',
    marginTop:20,
    marginLeft:20,
    maxHeight:'424px'
  },
  logInTxt:{
    color:theme.palette.primary.main,
    display:'inline',
  },
  body:{
    height:'350px'
  },
  txtCenter: {
    textAlign:"center"
  },
  historyContainer:{
    overflow:'auto',
    height:'100%'
  },
  table:{
    borderBottom:''
  },
  centered:{
    display:'flex',
    height:'100%'
  },
  mAuto:{
    margin:'auto'
  },
  '@media screen and (max-width: 1000px)': {
    historyCard:{
      marginLeft:0,
      maxHeight:'100%'
    }
  }
}))

const PomoHistory = () => { 
  const {auth} = React.useContext(AuthContext);
  const classes = historyStyles();
  
  return(
    <Card className={classes.historyCard}>
      <CardHeader 
        title={'History'} 
        className={classes.actionTitle} 
      />
      <CardContent className={classes.body}>
        {auth ? <UserHistory /> : <NoHistory />}
      </CardContent>
    </Card>
  )
}

const UserHistory = () => {
  const classes = historyStyles();

  const {history,getHistory} = React.useContext(HistoryContext)
  const {auth} = React.useContext(AuthContext)

  useEffect(() => {
    async function onLoad() {
      if (!auth) {
        return;
      }
  
      try {
        getHistory()
      } catch (e) {
        console.log(e);
      }
    }
  
    onLoad();
  }, [auth]);


  function loadHistory() {
    return API.get("pomodoro", "/get",{
    });
  }

  if(history === null){
    return <div className={classes.centered}><CircularProgress className={classes.mAuto} /></div>
  }
  else if(history.length === 0){ 
    return <div>No records found. Start using the pomodoro timer to begin!</div>
  }
  else{
    return (
      <div className={classes.historyContainer}>
        <HistoryTable history={history} />
      </div>
    )
  }
}

const NoHistory = () => {
  const classes = historyStyles();
  return <div className={classes.txtCenter}>Want to record and view your timer history? <div className={classes.logInTxt}>Log in</div> now to start </div>

}


//t is seconds
const formatTime = (t) => {
  let minutes = Math.floor(t/60);
  if(minutes < 60){ return (`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`)}
  else{
    let hours = Math.floor(minutes/60);
    let mins = minutes % 60;
    return (`${hours} ${hours === 1 ? 'hour' : 'hours'} and ${mins} ${mins === 1 ? 'minute' : 'minutes'}`)
  }

}

const HistoryTable = (props) => {
  const classes = historyStyles()
  return(
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" width={'25%'}>Date</TableCell>
            <TableCell align="left" width={'25%'}>End Time</TableCell>
            <TableCell align="left" width={'25%'}>Type</TableCell>
            <TableCell align="left" width={'25%'}>Total Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.history.map((row) => (
            <TableRow key={row.date}>
              <TableCell  align="left">{dayjs(row.date).format("MMM D, YYYY")}</TableCell>
              <TableCell align="left">{dayjs(row.date).format("h:mma")}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{formatTime(row.totalTime)}</TableCell>
            </TableRow>

          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}


const columns = [
  { field: 'Date', headerName: 'Date', width: 70 },
  { field: 'Time', headerName: 'First name', width: 130 },
  { field: 'type', headerName: 'Last name', width: 130 },
  { field: 'totalTime', headerName: 'Last name', width: 130 },
];

export default PomoHistory
