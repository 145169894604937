import React,{useState} from 'react';
import { Button, CardContent, Typography, TextField } from '@material-ui/core';
import loginStyles from './LoginStyles'
import emailRegex from '../../stringData/emailRegex'

const SignUpForm = (props) =>{
  const classes = loginStyles();
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")

  const signUp = () => {
    props.submitSignUp(email,password,name);
  }
  const verify = emailRegex.test(email) && password.length > 5 && name.length > 0;

  return(
    <CardContent className={classes.pomInfoContainer}>
        <Typography variant='h5' className={classes.title}>
          Create Account
        </Typography>
      <div className={classes.formContainer}>
        <div>
          <TextField size='medium' className={classes.textField} required id="standard-required" label="Name" defaultValue={name} onChange={(e)=>setName(e.target.value)} />
        </div>
        <br/>
        <div>
          <TextField size='medium' className={classes.textField} required id="standard-required" label="Email" defaultValue={email} onChange={(e)=>setEmail(e.target.value)} />
        </div>
        <br/>
        <div>
          <TextField
            id="standard-password-input"
            className={classes.textField} 
            required
            size='medium'
            label="Password"
            type="password"
            defaultValue={password} 
            onChange={(e)=>setPassword(e.target.value)}
          />
        </div> 
        <br/>
        <Button className={classes.textField} size='large' color='primary' variant='contained' onClick={signUp} disabled={!verify}>Create Account</Button>
        <div onClick={()=>props.setPage("Login")}>
          <Typography variant='caption' className={classes.caption}>
            Back to login
          </Typography>
        </div>
      </div>
    </CardContent>
  )
}

export default SignUpForm