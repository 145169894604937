import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const loginStyles = makeStyles( (theme) => ({
  loginBtn:{ 
    float:'right',
    lineHeight:'24px'
  },
  caption:{
    display:"block",
    marginTop:10,
    cursor:'pointer'
  },
  title:{
    textAlign:'center'
  },
  modal:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginContainer:{
    transform:'translate(0px,-100px)',
    backgroundColor:theme.palette.background.paper,
    maxWidth:500,
    width:'95%',
    height:350
  },
  formContainer:{ 
    textAlign:'center',
    marginTop:20
  },
  textField:{
    width:"75%"
  },
  signUpTxt:{
    color:theme.palette.primary.main,
    display:'inline',
    cursor:'pointer'
  },
  centered:{
    display:'flex',
    height:'100%'
  },
  mAuto:{
    margin:'auto'
  },
  error:{
    color:"salmon",
    textAlign:'center'
  },
  '@media screen and (max-width: 600px)': {

    textField:{
      width:'95%'
    }
  
  }
  
}))
export default loginStyles