import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions'
import { Button, CardContent,CardHeader, Typography,Modal, Fade,Backdrop,Switch } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import Cookies from 'js-cookie';
import PomoHistory from './PomoHistory';
import LoginContainer from '../Login/LoginContainer';
import AuthContext from '../../Context/AuthContext';
import { API } from "aws-amplify";
import ChangeThemeContext from '../../Context/ChangeThemecontext'
import HistoryContext from '../../Context/HistoryContext';

const initalPomoActions = {
  pom:{
    label:'Pom',
    time:'25:00',
    secTime:1500,
    shortcut:'shift+1'
  },
  shortBreak:{
    label:'Short Break',
    time:'5:00',
    secTime:300,
    shortcut:'shift+2'
  },
  pompom:{
    label:'PomPom',
    time:'50:00',
    secTime:3000,
    shortcut:'shift+3'
  },
  longBreak:{
    label:'Long Break',
    time:"10:00",
    secTime:600,
    shortcut:'shift+4'
  },
  nomnom:{
    label:'NomNom',
    time:'30:00',
    secTime:1800,
    shortcut:'shift+5'
  },
  pause:{
    label:'Start / Pause',
    time:'',
    secTime:'',
    shortcut:'space'
  }
  
}


const pomoStyles = makeStyles((theme) => ({
  pomoCard:{
    minWidth:300,
    display:'block'
  },
  pomoContent: {
    textAlign:'center'
  },
  pomoActions: {
    margin:'auto',
    width:'fit-content'
  },
  actionBtn: {
  },
  timerCard:{
    marginTop:20,
    flex:'40%',

  },
  timersContainer: {
    display:'flex',
    justifyContent:'space-evenly',
    flexWrap:'wrap',
    width:'fit-content',
    flex:'40%',
    minWidth:200
  },
  title:{
    display:'inline-block'
  },

  actionTitle:{
    margin:0,
    cursor:'pointer'
  },
  flexContainer:{
    display:'flex',
  },
  loginBtn:{ 
    float:'right'
  },
  nightSwitch:{
    float:'right',
    marginRight:8
  },
  header:{
    marginBottom:10,
    marginTop:10,
    padding:12
  },
  '@media screen and (max-width: 1000px)': {
    flexContainer:{
      flexDirection:"column"
    },
  }

}))

function PomoTimer() {
  const [pomoActions,setActions] = useState(null);
  const {auth} = React.useContext(AuthContext);
  //Update configuration
  const updateConfiguration = (config) => {
    Cookies.set("pomoActions",config);
    setActions(config);
  }

  if(pomoActions == null){
    if(Cookies.get('pomoActions')){
      setActions(JSON.parse(Cookies.get('pomoActions')));
    }
    else{
      updateConfiguration(initalPomoActions);
    }
  } 

  return <PomoTimerPage pomoActions={initalPomoActions} updateConfiguration={updateConfiguration} />
}


function PomoTimerPage(props){
  const {pomoActions,updateConfiguration} = props;
  const classes = pomoStyles();
  const {darkState,setDarkState} = React.useContext(ChangeThemeContext)
  const {getHistory} = React.useContext(HistoryContext);
  const [currentAction,setAction] = useState(pomoActions.pom);
  const [currentTime,setTime] = useState(currentAction.secTime);
  const [active,setActive] = useState(false);
  const [configOpen,openConfig] = useState(false);
  document.title = `PomoTimer (${formatTime(currentTime)})`;
  
  //Start timer
  const startPomo = () => {
    setActive(true);
  }

  const pausePomo = () => {
    setActive(false);
  }
  //Reset timer to max time
  const resetPomo = () => {
    setActive(false);
    setTime(currentAction.secTime);
    savePomo()
  }

  //Change action to new one
  const changePomo = (action) => {
    savePomo()
    setAction(action);
    setActive(false);
    setTime(action.secTime);
  }
  async function savePomo(){
    if(currentAction.secTime - currentTime < 60) return
    let body = {totalTime:currentAction.secTime - currentTime,type:currentAction.label}
    try {
      await sendHistoryRequest(body);

      getHistory();
    } catch (e) {
      console.log(e);
      }
  }
  
  function sendHistoryRequest(body) {
    return API.post("pomodoro", "/add", {
      body: body
    });
  }
  
  //Setup timer when active is true, or cancel it.
  useEffect(() => {
    let interval = null;
    if(!active || currentTime == 0){
      clearInterval(interval);
    }
    else if(active){
      interval = setInterval(() => {
        setTime(currentTime - 1);
      },document.hidden ? 500 : 1000)
    }
    document.addEventListener("keydown", handleKeyDown);


    window.onbeforeunload = () => {
      if(active){
        savePomo();
      } 

    }
    return () => { 
      clearInterval(interval)
      document.removeEventListener("keydown", handleKeyDown);

    };
  })
  const handleKeyDown = (e) => {
    if(e.key === ' '){ return setActive(!active)}
      else if(active) return
      else if(e.key === '!') changePomo(pomoActions.pom);
      else if(e.key === '@') changePomo(pomoActions.shortBreak);
      else if(e.key === '#') changePomo(pomoActions.pompom);
      else if(e.key === '$') changePomo(pomoActions.longBreak);
      else if(e.key === '%') changePomo(pomoActions.nomnom);
      return
  }

  return(
    <div>
        <Card className={classes.header}>
          <Typography variant="h5" className={classes.title}>
            Pomodoro
          </Typography>
          <LoginContainer />          
          <Switch checked={darkState} className={classes.nightSwitch} onChange={() => setDarkState(!darkState)} />
        </Card>
      <Card variant='outlined' className={classes.pomoCard}>
        <CardContent className={classes.pomoContent}>
          <Typography variant='h2' className={classes.timer}>
            {formatTime(currentTime)}
          </Typography>
          <CardActions className={classes.pomoActions}>
            <Button color='primary' variant='contained' onClick={startPomo} className={classes.actionBtn}>Start</Button>
            <Button color='default' variant='contained' onClick={pausePomo} className={classes.actionBtn}>Pause</Button>
            <Button color='secondary' variant='contained' onClick={resetPomo} className={classes.actionBtn}>Reset</Button>  
          </CardActions>
        </CardContent>
      </Card>

      <div className={classes.flexContainer}>
        <Card className={classes.timerCard} >
          <CardHeader 
            title={'Actions'} 
            className={classes.actionTitle} 
          />
          <CardContent className={classes.timersContainer}>
            {Object.keys(pomoActions).map( (key) => (
              <PomoInfoDisplay 
                key={pomoActions[key].label} 
                onClick={() => changePomo(pomoActions[key])} 
                label={pomoActions[key].label} 
                shortcut={pomoActions[key].shortcut} 
                time={pomoActions[key].time} 
              />
            ))}
          </CardContent>
        </Card>
        
        <PomoHistory />

      </div>
      <Modal
        className={classes.modal}
        open={configOpen}
        onClose={openConfig}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={configOpen}>
        <div className={classes.paper}>
            <h2 id="transition-modal-title">Transition modal</h2>
            <p id="transition-modal-description">react-transition-group animates me.</p>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

const formatTime = (secs) => {
  let minutes = Math.floor(secs/60);
  let seconds = Math.floor(secs%60);
  return `${('0'+minutes).slice(-2)}:${('0'+seconds).slice(-2)}`

}


const PomoInfoDisplay = (props) => {
  const classes = infoStyles();
  const {label,shortcut,time} = props;
  return(
    <Card className={classes.pomInfoCard}  onClick={props.onClick}>
      <CardContent className={classes.pomInfoContainer}>
        <div className={classes.pomLabel}>
          <Typography variant='subtitle2'>{label}</Typography>
          <Typography variant='caption'>{shortcut}</Typography>
        </div>
        {props.label !== 'Start / Pause' ? <div className={classes.pomDescTime}>
          <Typography variant='subtitle2'>
            {time}
          </Typography>
        </div> : "" }
      </CardContent>
    </Card>
  )

}

const infoStyles = makeStyles( (theme) =>({
  pomInfoCard: {
    width:'fit-content',
    margin:10,
    cursor:'pointer',
    backgroundColor:theme.palette.background.default
  },
  pomInfoContainer:{
    display:'flex',
    minHeight:60,
    width:150,
  },
  pomLabel:{
    flex:70,
    display:'flex',
    flexDirection:'column',
    margin:'auto'
  },
  pomTitle: {
  },
  pomShortcut: {
  },
  pomDescTime: {
    flex:30,
  },
  '@media screen and (max-width: 600px)': {
    flexContainer:{
      flexDirection:"column"
    },
    pomInfoContainer:{
      width:130,
      padding:8
    }
  }
}))


export default PomoTimer

/*

-Add Timer interface                                      x
-Add time ticker - decrement ever 1s                      x
-update interface and page title every second             x
-Add start, pause and end buttons                         x
-Add pom, pompom, short and long breaks                   x

-Add configuation modal popup                              
-Add configuration settings 
-Move pom configurations to cookies                       x
-Add function to update retrieve cookies on load          x
-Add function to update cookie configs                    x

-Add activity logs
-Make activity log dynamodb syntax
-Add activity log to dynamodb
-Add routes to get activity logs

*/