import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions'
import { Button, CardContent,CardHeader, Typography,Modal, Fade,Backdrop, TextField } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import Cookies from 'js-cookie';
import AuthContext from '../../Context/AuthContext'
import { Auth } from "aws-amplify";
import loginStyles from './LoginStyles'

const VerifyAccountForm = (props) => {
  const classes = loginStyles();
  const [code,setCode] = useState("")

  const verifyCode = () => {
    props.verifyCode(code);
  }

  const verify = code.length > 4
  return(
    <CardContent className={classes.pomInfoContainer}>
            <Typography variant='h5' className={classes.title}>
              Verify Account
            </Typography>
          <div className={classes.title}>
        <Typography variant='caption' className={classes.title}>
          A verification code has been sent to your email! Please enter it below.
        </Typography>
        </div>
      <div className={classes.formContainer}>
        <div>
          <TextField size='medium' className={classes.textField} required id="standard-required" label="Verification Code" defaultValue={code} onChange={(e)=>setCode(e.target.value)} />
        </div>
        <br/>
        <Button className={classes.textField} size='large' color='primary' variant='contained' onClick={verifyCode} disabled={!verify}>Verify Account</Button>
        <div onClick={()=>props.setPage("Login")}>
          <Typography variant='caption' className={classes.caption}>
            Back to login
          </Typography>
        </div>
      </div>
    </CardContent>
)
}


export default VerifyAccountForm;