import React, {useEffect, useState,KeyboardEventHandler } from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  orange,
  lightBlue,
  deepPurple,
  deepOrange,
} from "@material-ui/core/colors";
import {CssBaseline,Switch } from "@material-ui/core";
import AuthContext from '../../Context/AuthContext'
import HistoryContext from '../../Context/HistoryContext';
import ChangeThemeContext from '../../Context/ChangeThemecontext'
import { Auth,API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  container:{
    width:'90%',
    maxWidth:1000,
    margin:'auto'
  },
 
  '@media screen and (max-width: 600px)': {
    container:{
      width:'95%'
    }
  }
}));

export default function Main(props) {
  const classes = useStyles();
  const [auth,setAuth] = useState(false);
  const [history,setHistory] = useState(null);
  const [darkState, setDarkState] = useState(true);
  const palletType = darkState ? "dark" : "light";
  const mainPrimaryColor = darkState ? orange[500] : lightBlue[500];
  const mainSecondaryColor = darkState ? deepOrange[900] : deepPurple[500];

  useEffect(() => {
    onLoad();
  }, []);


  //const backgroundColor = darkState ?
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
    },
  });

  //Dark theme handler
  const handleThemeChange = () => {
    setDarkState(!darkState);
    
  };
  async function onLoad() {
    try {
      await Auth.currentSession();
      setAuth(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }


  async function getHistory(){
      if (!auth) {
        return;
      }
      try {
        const records = await loadHistory();
        setHistory(records.reverse());
      } catch (e) {
        console.log(e);
      }
    }



  function loadHistory() {
    return API.get("pomodoro", "/get",{
    });
  }



  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AuthContext.Provider value={{auth:auth,setAuth:setAuth}}>
        <ChangeThemeContext.Provider value={{darkState:darkState,setDarkState:setDarkState}}>
        <HistoryContext.Provider value={{history:history,getHistory:getHistory}}>
          <div className={classes.container}>
            {props.children}
          </div>
        </HistoryContext.Provider>
        </ChangeThemeContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}


/*
            <Switch checked={darkState} onChange={handleThemeChange} />
*/



