import React from "react";
import "./App.css";
import Main from "./Pages/Layout/Main";
import axios from "axios";
import PomoTimer from './Pages/PomoTimer/PomoTimer'
function App() {

  axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_ENDPOINT
    : process.env.REACT_APP_PRODUCTION_ENDPOINT;


  return (
    <div className="App">
      <Main>
        <PomoTimer />
      </Main>
    </div>
  );
}

export default App;
