import React,{useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import { Button,Modal, Fade,Backdrop, CircularProgress } from '@material-ui/core';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import VerifyAccountForm from './VerifyAccountForm'
import AuthContext from '../../Context/AuthContext'
import loginStyles from './LoginStyles'
import { Auth } from "aws-amplify";

const LoginContainer = (props) => {
  const classes = loginStyles();
  const {auth,setAuth} = React.useContext(AuthContext);
  const [page,setPage] = useState("Login")
  const [loginModal,setLoginModal] = useState(false);
  const [user,setUser] = useState(null);
  const [error,setError] = useState("")
  async function submitLogin(email,password){
    setPage("Submitting")
    try {
      await Auth.signIn(email, password);
      setLoginModal(false);
      setAuth(true);
    } catch (e) {
      if(e.message === "User is not confirmed."){
        setPage("Verify")
        setUser({email:email,password:password})
        return
      }
      console.log(e.message)
      setError(e.message);
      setPage("Login")
    }
  } 

  async function submitSignUp(email,password,name){
    setPage("Submitting")

    try {
      await Auth.signUp({
          username:email,
          password:password,
          attributes: {
              name:name,
          }
      });
      setUser({email:email,password:password});
      setPage("Verify")
  } catch (e) {
      setError(e.message);
      setPage("Signup");
    }
  }

  async function verifyCode(code){
    setPage("Submitting")

    try{
      await Auth.confirmSignUp(user.email,code);
      await Auth.signIn(user.email, user.password);

      setAuth(true);
      setLoginModal(false);

    }
    catch (e) {
      setError(e.message);
      setPage("Verify")
  }
  }


  async function logout(){
    await Auth.signOut();
    setAuth(false)
    setPage("Login")
  }

    return(
    <>
    {auth ? 
      <Button variant='contained' color='primary' size='small' className={classes.loginBtn} onClick={() => logout()}>Logout</Button>:
      <Button variant='contained' color='primary' size='small' className={classes.loginBtn} onClick={() => setLoginModal(true)}>Log in</Button>
      
    }

      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={loginModal}
      onClose={() => setLoginModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={loginModal}>
        <Card className={classes.loginContainer}>
          {page === "Login" ? (
            <LoginForm submitLogin={submitLogin} error={error} setPage={setPage}/>
          ) : page === 'Signup' ? 
          (
            <SignUpForm submitSignUp={submitSignUp} error={error} setPage={setPage}/>
          ) : page === 'Verify' ? <VerifyAccountForm error={error} verifyCode={verifyCode} /> : <div className={classes.centered}> <CircularProgress className={classes.mAuto}/> </div>}
        
          <div className={classes.error}>{error}</div>
        </Card>
      </Fade>
    </Modal>
  </>

    )

}

export default LoginContainer