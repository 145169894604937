import React,{useRef, useState} from 'react';

import { Button, CardContent, Typography, TextField, CircularProgress } from '@material-ui/core';
import emailRegex from '../../stringData/emailRegex'
import loginStyles from './LoginStyles'


const LoginForm = (props) => {
  const classes = loginStyles();
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [submitting,setSubmitting] = useState(false)

  const submitLogin = () => {
    props.submitLogin(email,password)
  }
  const verify = emailRegex.test(email) && password.length > 5

  if(submitting){
    return(
      <CardContent className={classes.pomInfoContainer}>
        <CircularProgress className={classes.mauto} />
      </CardContent>
    )
  }
  else{
  return(
      <CardContent className={classes.pomInfoContainer}>
          <Typography variant='h5' className={classes.title}>
            Login
          </Typography>
        <div className={classes.formContainer}>
          <div>
            <TextField size='medium' className={classes.textField} required id="standard-required" label="Email" defaultValue={email} onChange={(e)=>setEmail(e.target.value)} />
          </div>
          <br/>
          <div>
            <TextField
              id="standard-password-input"
              className={classes.textField} 
              required
              size='medium'
              label="Password"
              type="password"
              defaultValue={password} 
              onChange={(e)=>setPassword(e.target.value)}
            />
           </div> 
           <br/>
           <Button className={classes.textField} size='large' color='primary' variant='contained' disabled={!verify} onClick={submitLogin}>Login</Button>
          <Typography variant='caption' className={classes.caption}>
            Forgot Password?
          </Typography>
          <Typography variant='subtitle2' className={classes.caption}>
            New Here? <div className={classes.signUpTxt} onClick={()=>props.setPage("Signup")}>Sign Up Now </div>
          </Typography>
        </div>
        {props.error !== "" ? "" : <div>{props.error}</div>}

      </CardContent>
    )
  }
}

export default LoginForm