const config = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://g8rxl1xpsg.execute-api.us-east-2.amazonaws.com/prod/pomodoro",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_78OMqY9SI",
    APP_CLIENT_ID: "7190o87k2i0ca2vru7g356o1bq",
    IDENTITY_POOL_ID: "us-east-2:a4ddd745-c80e-4ec6-b90e-1fff6041236d",
  },
};

export default config;